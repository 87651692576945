/**
 * 获取支付方式工具类
 *
 * @author terrfly
 * @site https://www.jeepay.vip
 * @date 2021/5/8 07:18
 */

import config from '@/config'

const getToPageRouteName = function () {
    const payWay = getPayWay();
    return  payWay? payWay.routeName : null
}

const getPayWay = function () {

    const userAgent = navigator.userAgent;
 
    const mobile = /windows phone|iphone|ipad|ipod|android|blackberry|mini|windows ce|palm/i.test(userAgent.toLowerCase())
  
    console.log(userAgent,'userAgent')
    // return config.payWay.WXPAY;
    if(userAgent.indexOf("MicroMessenger") >= 0){
        return config.payWay.WXPAY;
    }

    if(userAgent.indexOf("AlipayClient") >= 0){
        return config.payWay.ALIPAY;
    }
    if(mobile){
        return config.payWay.MOBILE;
    }
    return config.payWay.PC;

}


export default { getToPageRouteName: getToPageRouteName,
    getPayWay: getPayWay

}
