import axios from 'axios'
// import JSZip from 'jszip'
// import FileSaver from 'file-saver'


/**
 * 时间转换
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (let i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xdc00 && code <= 0xdfff) i--
  }
  return s
}
/**
 * 计算字段符串长度
 * @param {string} str input value
 * @returns
 */
export function stringLength(str) {
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    let c = str.charCodeAt(i)
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      len++
    } else {
      len += 2
    }
  }
  return len
}
/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }
    return result
  }
}

/**
 * 对象深拷贝
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}
/**
 * 随机生成字符串
 * @param {number} e 字符数
 */
export function randomString(e) {
  e = e || 32
  let t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
    a = t.length,
    n = ''
  for (let i = 0; i < e; i++) {
    n += t.charAt(Math.floor(Math.random() * a))
  }
  return n
}

/**
 * 向前或向后多少天
 * @param {number} number 字符数
 */
export function frontOrbackDate(number) {
  let dateNow = new Date()
  let dateNew = new Date(dateNow)
  dateNew.setDate(dateNow.getDate() + number)
  return dateNew.getFullYear() + '-' + (dateNew.getMonth() + 1) + '-' + dateNew.getDate()
}

/**
 * 向前或向后多少天
 * @param {number} number 字符数
 */
export function frontOrbackDateTime(number) {
  let dateNow = new Date()
  let dateNew = new Date(dateNow)
  dateNew.setDate(dateNow.getDate() + number)
  return (
    dateNew.getFullYear() +
    '-' +
    (dateNew.getMonth() + 1) +
    '-' +
    dateNew.getDate() +
    ' ' +
    dateNew.getHours() +
    ':' +
    dateNew.getMinutes() +
    ':' +
    dateNew.getDate()
  )
}

/**
 * 金额格式化保留两位小数
 * @param {number} money
 */
export function moneyFormat(money) {
  if (isNaN(money)) {
    return '0.00'
  }
  return (money / 100).toFixed(2)
}

/**
 * 号码隐号前三后四
 * @param {String} str
 */
export function mobileFormat(str) {
  if (!str) return ''
  return str.replace(/^(.{3})(?:\d+)(.{4})$/, "$1****$2")
}

/**
 * 秒转时分秒
 * @param {number} money
 */
export function timeFormatDateHour(value) {
  if (value) {
    let theTime = parseInt(value) // 秒
    let middle = 0 // 分
    let hour = 0 // 小时

    if (theTime > 60) {
      middle = parseInt(theTime / 60)
      theTime = parseInt(theTime % 60)
      if (middle > 60) {
        hour = parseInt(middle / 60)
        middle = parseInt(middle % 60)
      }
    }
    let result = '' + parseInt(theTime) + '秒'
    if (middle > 0) {
      result = '' + parseInt(middle) + '分' + result
    }
    if (hour > 0) {
      result = '' + parseInt(hour) + '小时' + result
    }
    return result
  } else {
    return ''
  }
}

/**
 * 创建canvas并下载图片
 * @param {string} url
 * @param {string} idName
 * @param {string} fileName
 */
export function createCanvasDownload(url, idName, fileName) {
  let img = document.getElementById(idName)
  let canvas = document.createElement('canvas')
  canvas.id = 'myCanvas'
  canvas.width = img.width
  canvas.height = img.height
  let ctx = canvas.getContext('2d')
  img.setAttribute('crossOrigin', 'anonymous')
  img.src = url + '?time=' + new Date().valueOf()
  img.onload = () => {
    ctx.drawImage(img, 0, 0, img.width, img.height)
    let oA = document.createElement('a')
    oA.download = fileName || '下载' // 设置下载的文件名，默认是'下载'
    oA.href = canvas.toDataURL('image/png')
    document.body.appendChild(oA)
    oA.click()
    oA.remove()
    canvas.remove()
  }
}

export function createCanvasDownloadImage(imgsrc, name) {
  // 下载图片地址和图片名
  let image = new Image()
  image.src = imgsrc
  image.setAttribute('crossOrigin', 'anonymous')
  // 解决跨域 Canvas 污染问题
  image.onload = () => {
    let canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    let context = canvas.getContext('2d')
    // image.setAttribute("crossOrigin", "anonymous");
    context.drawImage(image, 0, 0, image.width, image.height)
    let oA = document.createElement('a') // 生成一个a元素
    let url = canvas.toDataURL('image/png') //得到图片的base64编码数据
    oA.download = name || '下载' // 设置图片名称
    oA.href = url // 将生成的URL设置为a.href属性
    document.body.appendChild(oA)
    oA.click()
    oA.remove()
    canvas.remove()
  }
}

/**
 * 批量下载
 */
export function batchDownload(imgs, names) {
  const data = imgs
  const zip = new JSZip()
  const cache = {}
  const promises = []

  // 下载文件
  function getFile(url) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer'
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(error => {
          reject(error.toString())
        })
    })
  }

  data.forEach((item, index) => {
    const promise = getFile(item).then(data => {
      // 下载文件, 并存成ArrayBuffer对象
      // const arr_name = item.split("/");
      // let file_name = arr_name[arr_name.length - 1] // 获取文件名
      // if (file_name.indexOf('.png') == -1) {
      //    file_name = file_name + '.png'
      // }
      let file_name = names[index] + '.png'
      zip.file(file_name, data, {
        binary: true
      }) // 逐个添加文件
      cache[file_name] = data
    })
    promises.push(promise)
  })
  Promise.all(promises).then(() => {
    zip
      .generateAsync({
        type: 'blob'
      })
      .then(content => {
        // 生成二进制流
        FileSaver.saveAs(content, '二维码.zip') // 利用file-saver保存文件
      })
  })
}

/**
 * 生成uuid
 */
export function uuid() {
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * 过滤特殊字符
 */
export function StringValFilter(str) {
  let s = ''
  if (str.length === 0) {
    return ''
  }
  s = str.replace(/&amp;/g, '&')
  s = s.replace(/&lt;/g, '<')
  s = s.replace(/&gt;/g, '>')
  s = s.replace(/&nbsp;/g, ' ')
  s = s.replace(/&#39;/g, "'")
  s = s.replace(/&quot;/g, '"')
  return s
}

/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {cb}
 */
export function urlToBlob(url, cb) {
  let xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response);
    }
  };
  xhr.send();
}

export function downloadBlob(blob, filename = '下载') {
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const elink = document.createElement('a')
    elink.download = filename
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink)
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, filename)
  }
}

/**
* 金额加,号
* @param {object} params 请求参数
*/
export function formatMoney(n) {
  if (n) {
    if (parseFloat(n) == 0) {
      return "0.00";
    }
    n = parseFloat(n).toFixed(2);
    let num = n.toString();
    let decimals = "";
    // 判断是否有小数
    num.indexOf(".") > -1 ? (decimals = num.split(".")[1]) : decimals;
    // let len = num.length;
    let len = num.split(".")[0].length;
    if (len <= 3) {
      return num;
    } else {
      let temp = "";
      let remainder = len % 3;
      decimals ? (temp = "." + decimals) : temp;
      if (remainder > 0) {
        // 不是3的整数倍
        return (
          num.slice(0, remainder) +
          "," +
          num
            .slice(remainder, len)
            .match(/\d{3}/g)
            .join(",") +
          temp
        );
      } else {
        // 是3的整数倍
        return (
          num
            .slice(0, len)
            .match(/\d{3}/g)
            .join(",") + temp
        );
      }
    }
  } else {
    return "0.00";
  }
}

/**
* 数字加,号
* @param {object} params 请求参数
*/
export function formatNumber(n, fixed = 0) {
  if (n) {
    if (parseFloat(n) == 0) {
      return "0";
    }
    n = parseFloat(n).toFixed(fixed);
    let num = n.toString();
    let decimals = "";
    // 判断是否有小数
    num.indexOf(".") > -1 ? (decimals = num.split(".")[1]) : decimals;
    // let len = num.length;
    let len = num.split(".")[0].length;
    if (len <= 3) {
      return num;
    } else {
      let temp = "";
      let remainder = len % 3;
      decimals ? (temp = "." + decimals) : temp;
      if (remainder > 0) {
        // 不是3的整数倍
        return (
          num.slice(0, remainder) +
          "," +
          num
            .slice(remainder, len)
            .match(/\d{3}/g)
            .join(",") +
          temp
        );
      } else {
        // 是3的整数倍
        return (
          num
            .slice(0, len)
            .match(/\d{3}/g)
            .join(",") + temp
        );
      }
    }
  } else {
    return "0";
  }
}


/**
* 判断一个url链接是否是图片
* @param {stying} url 请求参数
*/
export function urlIsImage(url) {
  return new Promise((resolve, reject) => {
    let ImgObj = new Image()
    ImgObj.src = url
    ImgObj.onload = () => {
      resolve(true);
    };
    ImgObj.onerror = () => {
      resolve(false);
    };
  }).catch(err => { });
}


export function getArraybuffer(url) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (xhr.status === 200) {
        resolve(xhr.response);
      } else {
        reject()
      }
    };
    xhr.send();
  })
}


export function downloadZip(zipData = [], zipName = '文件压缩包.zip') {
  const zip = new JSZip()
  const promises = []
  zipData.forEach((item, index) => {
    const promise = getArraybuffer(item.url).then(data => {
      let fileName = item.filename || index
      zip.folder(item.fileType).file(fileName, data, {
        binary: true
      }) // 逐个添加文件
    })

    promises.push(promise)
  })
  Promise.all(promises).then(() => {
    zip.generateAsync({ type: 'blob' })
      .then(content => {
        // 生成二进制流
        FileSaver.saveAs(content, zipName) // 利用file-saver保存文件
      })
  })
}

/**
* 获取文件url后缀名
* @param {stying} filePath 请求参数
*/
export function getFileSuffix(filePath) {
  const _index = filePath.lastIndexOf('.')
  if (_index != -1)
    return filePath.substring(_index + 1, filePath.length).toLowerCase()
  else
    return ''
}

// 判断是否是json字符串对象
export function isJson(str) {
  if (typeof str == 'string') {
    try {
      let obj = JSON.parse(str);
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log('error：' + str + '!!!' + e);
      return false;
    }
  }
}
export function isAliOrWx() {
  let typeBower = '';
  let browser = {
     versions: function() {
        let u = navigator.userAgent,
           app = navigator.appVersion;
        return { //移动终端浏览器版本信息
           trident: u.indexOf('Trident') > -1, //IE内核
           presto: u.indexOf('Presto') > -1, //opera内核
           webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
           gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
           mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
           ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
           android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
           iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
           iPad: u.indexOf('iPad') > -1, //是否iPad
           webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
        };
     }(),
     language: (navigator.browserLanguage || navigator.language).toLowerCase()
  };
  if(browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
     let ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
     if(ua.match(/Alipay/i)=="alipay"){
        typeBower = 'ali';
     }
     if(ua.match(/MicroMessenger/i) == "micromessenger") {
       typeBower = 'wx';
     }
  }
  return typeBower;
};