
export default  {
  hemafu: {
    homePageUrl: "https://www.hemafu.cn",
    payTitle: '河马付收银',
    panelFooter: '收银 © 2024 XC All Rights Reserved.',
    appid:'wx84ab1e060b5df8a0',
    aliappid:'2021004136643812',
  },
  xc: {
    homePageUrl: "https://www.xinchuwl.cn",
    payTitle: '鑫楚收银',
    panelFooter: '收银 © 2024 XC All Rights Reserved.',
    appid:'wx84ab1e060b5df8a0',
    aliappid:'2021004136643812',
  },
  ha: {
    homePageUrl: "https://zujihua.com",
    payTitle: '豹付收银',
    panelFooter: '收银 © 2024 XC All Rights Reserved.',
    appid:'wx84ab1e060b5df8a0',
    aliappid:'2021004136643812',
  },
  blindBox: {
    payTitle: '益嘉收银',
    panelFooter: '收银 © 2024 YIJIA All Rights Reserved.',
    appid:'wx84ab1e060b5df8a0',
    aliappid:'2021004136643812',
  }
}