/*
 *  全局定义信息
 *
 *  @author terrfly
 *  @site https://www.jeepay.vip
 *  @date 2021/5/8 07:18
 */

const errorPageRouteName = 'Error' //错误页面名称定义
const SUBMIT = 'submit' //错误页面名称定义
const passGuardRouteList = [errorPageRouteName,SUBMIT]  // 不进入路由守卫的name

/** 定义支付方式 **/
const payWay = {
    WXPAY : {wayCode: "wxpay", routeName: "CashierWxpay"},
    ALIPAY : {wayCode: "alipay", routeName: "CashierAlipay"},
    PC : {wayCode: "pc", routeName: ""},
    MOBILE : {wayCode: "mobile", routeName: ""}
}

export default {
    errorPageRouteName: errorPageRouteName,
    passGuardRouteList: passGuardRouteList,
    urlTokenName: "token", //URL传递的token名称
    payWay: payWay,
    cacheToken: "",
    params:null
}